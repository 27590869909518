<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-standar">
      <div class="container-standar mb-10 pad-20">
        <h2 class="text-primary font-weight-7 mb-30 font-30 text-center">TENTANG KAMI</h2>
        <div class="shadow card pad-20 text-jsutify ">
              <h2 class="text-dark font-weight-7 font-24 text-center">Aplikasi SAIK+</h2>
                <h3 class="text-grey font-weight-6 text-center font-16 mt-2 text-center mb-30 "> Hak Cipta © 2019-2022 Pemerintah Daerah Papua Barat</h3>
                        <p class="font-18 font-weight-4 text-grey"> <span class="text-dark font-weight-7">Aplikasi SAIK+</span> dikembangkan oleh Pemerintah Daerah Papua Barat dalam rangka pelaksanaan Program Strategis Peningkatan Pembangunan Kampung – Otonomi Khusus (PROSPPEK OTSUS) dan dikembangkan dengan semangat Inovasi, Integrasi dan Kolaborasi.<br>  <br> </p>
                         <p class="font-18 font-weight-4 text-grey">Aplikasi ini berfungsi untuk meningkatkan kualitas pengelolaan data dan informasi dasar kampung sehingga dapat mendukung administrasi pemerintahan, perencanaan pembangunan, peningkatan layanan dasar, dan pengembangan kegiatan ekonomi kampung.</p>
                          <br>
                          <p class="font-18 font-weight-4 text-grey"> <strong class="text-danger">Perhatian :</strong> Aplikasi SAIK+ adalah milik Pemerintah Daerah Papua Barat. Segala tindakan terhadap aplikasi yang dapat mengganggu dan menghambat keberhasilan pendataan Kampung akan ditindak sesuai Undang-undang dan hukum yang berlaku.</p>
        </div>
        <!-- <div role="tablist" class="mt-10">
          <b-card no-body class="mb-10">
            <b-card-header header-tag="header" class="card shadow" role="tab">
              <b-button block href="#" v-b-toggle.accordion-1 class="btn-overide">
                1. Apa itu Aplikasi SAIK+
                <i class="fa fa-angle-right text-primary float-right"></i>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text
                  class="font-18 font-weight-4 text-grey"
                >Sistem Aplikasi SAIK+ adalah Aplikasi penjaring data kampung dan kelurahan di seluruh wilayah Papua Barat yang berfungsi untuk meningkatkan kualitas pengelolaan data dan informasi dasar kampung sehingga dapat mendukung administrasi pemerintahan, perencanaan pembangunan, peningkatan layanan dasar, dan pengembangan kegiatan ekonomi kampung.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-10">
            <b-card-header header-tag="header" class="card shadow" role="tab">
              <b-button block href="#" v-b-toggle.accordion-2 class="btn-overide font-weight-7">
                2. Mengapa kampung harus menggunakan Aplikasi SAIK+?
                <i
                  class="fa fa-angle-right text-primary float-right"
                ></i>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text
                  class="font-18 font-weight-4 text-grey"
                >Aplikasi SAIK+ ini dapat meningkatkan tata kelola pemerintahan dan pembangunan di tingkat kampung / kelurahan dan distrik dalam bidang ekonomi & pelayanan dasar dalam rangka pelaksanaan PROSPPEK OTSUS sehingga dapat memperkuat pemerintah provinsi, kabupaten, dan kampung dalam menjalankan tugas yang ditetapkan peraturan perundang-undangan.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-10">
            <b-card-header header-tag="header" class="card shadow" role="tab">
              <b-button block href="#" v-b-toggle.accordion-3 class="btn-overide font-weight-7">
               3. Apa akibatnya jika kampung menolak menggunakan Aplikasi SAIK+?
                <i
                  class="fa fa-angle-right text-primary float-right"
                ></i>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text
                  class="font-18 font-weight-4 text-grey"
                >Data Kampung di Aplikasi SAIK+ digunakan sebagai acuan data untuk mendukung administrasi pemerintahan, perencanaan pembangunan, peningkatan layanan dasar & pengembangan kegiatan ekonomi kampung. Apabila kampung tidak berpartisipasi aktif, maka kampung akan rugi karena sistem administrasi dan informasi kampung tidak dikelola dengan baik sehingga kampung tersebut otomatis tidak akan tersentuh program-program Pemerintah Daerah Papua Barat.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-10">
            <b-card-header header-tag="header" class="card shadow" role="tab">
              <b-button block href="#" v-b-toggle.accordion-4 class="btn-overide font-weight-7">
             4. Apakah hanya wilayah Papua Barat saja yang dapat menggunakan Aplikasi SAIK+?
                <i
                  class="fa fa-angle-right text-primary float-right"
                ></i>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text
                  class="font-18 font-weight-4 text-grey"
                >Ya, semua kampung dan kelurahan di Provinsi Papua Barat.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
           <b-card no-body class="mb-10">
            <b-card-header header-tag="header" class="card shadow" role="tab">
              <b-button block href="#" v-b-toggle.accordion-5 class="btn-overide font-weight-7">
             5. Bagaimana Cara mendaftarkan untuk kampung yang belum punya akses SAIK+?
                <i
                  class="fa fa-angle-right text-primary float-right"
                ></i>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text
                  class="font-18 font-weight-4 text-grey"
                >Kampung mendaftarkan ke dinas terkait dengan menyurat atas nama kepala kampung.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  }
};
</script>